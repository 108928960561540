import { useMoralisDapp } from 'providers/MoralisDappProvider/MoralisDappProvider'
import { useMoralis } from 'react-moralis'
import { getEllipsisTxt } from 'helpers/formatters'
import Blockie from './Blockie'
import { Button, Card, Modal } from 'antd'
import { useState } from 'react'
import Address from './Address/Address'
import { SelectOutlined } from '@ant-design/icons'
import { getExplorer } from 'helpers/networks'
const styles = {
  account: {
    height: '42px',
    padding: '0 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //width: 'fit-content',
    width: '60px',
    borderRadius: '12px',
    backgroundColor: 'rgb(244, 244, 244)',
    cursor: 'pointer',
  },
  text: {
    color: '#958006',
  },
}

function Account() {
  const { authenticate, isAuthenticated, logout } = useMoralis()
  const { walletAddress, chainId } = useMoralisDapp()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalVisible1, setIsModalVisible1] = useState(false)
  if (!isAuthenticated) {
    return (
      <>
        <div
          style={styles.account}
          onClick={() => {
            authenticate({ signingMessage: 'Welcome to VEver NFT Market!' })
            //setIsModalVisible1(true)
          }}
        >
          {/* <p style={styles.text}>Authenticate</p>*/}
          <p style={styles.text}>Login</p>
        </div>
        <Modal
          visible={isModalVisible1}
          footer={null}
          onCancel={() => setIsModalVisible1(false)}
          bodyStyle={{
            padding: '15px',
            fontSize: '17px',
            fontWeight: '500',
          }}
          style={{ fontSize: '16px', fontWeight: '500' }}
          width="400px"
        >
          Account: {isAuthenticated}
          <Card
            style={{
              marginTop: '10px',
              borderRadius: '1rem',
            }}
            bodyStyle={{ padding: '15px' }}
          >
            <Address
              avatar="left"
              size={6}
              copyable
              style={{ fontSize: '20px' }}
            />
            <div style={{ marginTop: '10px', padding: '0 10px' }}>
              <a
                href={`${getExplorer(chainId)}/address/${walletAddress}`}
                target="_blank"
                rel="noreferrer"
              >
                <SelectOutlined style={{ marginRight: '5px' }} />
                View on Explorer
              </a>
            </div>
          </Card>
          <Button
            size="large"
            type="primary"
            style={{
              width: '100%',
              marginTop: '10px',
              borderRadius: '0.5rem',
              fontSize: '16px',
              fontWeight: '500',
            }}
            onClick={() => {
              //logout()
              setIsModalVisible1(false)
            }}
          >
            Test sign Wallet
          </Button>
        </Modal>
      </>
    )
  }

  return (
    <>
      <div style={styles.account} onClick={() => setIsModalVisible(true)}>
        <p style={{ marginRight: '5px', ...styles.text }}>
          {/*getEllipsisTxt(walletAddress, 6)*/}
          logout
        </p>
        {/* <Blockie currentWallet scale={3} /> */}
      </div>
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        bodyStyle={{
          padding: '15px',
          fontSize: '17px',
          fontWeight: '500',
        }}
        style={{ fontSize: '16px', fontWeight: '500' }}
        width="400px"
      >
        Account
        <Card
          style={{
            marginTop: '10px',
            borderRadius: '1rem',
          }}
          bodyStyle={{ padding: '15px' }}
        >
          <Address
            avatar="left"
            size={6}
            copyable
            style={{ fontSize: '20px' }}
          />
          <div style={{ marginTop: '10px', padding: '0 10px' }}>
            <a
              href={`${getExplorer(chainId)}/address/${walletAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              <SelectOutlined style={{ marginRight: '5px' }} />
              View on Explorer
            </a>
          </div>
        </Card>
        <Button
          size="large"
          type="primary"
          style={{
            width: '100%',
            marginTop: '10px',
            borderRadius: '0.5rem',
            fontSize: '16px',
            fontWeight: '500',
          }}
          onClick={() => {
            logout()
            setIsModalVisible(false)
          }}
        >
          Disconnect Wallet
        </Button>
      </Modal>
    </>
  )
}

export default Account
