export const networkCollections = {
  '0x13881': [
    //Add Your Collections here
    // {
    //   image:
    //     'https://ipfs.infura.io/ipfs/QmenANH7Qs5EsFMECyp1KbWNDXZ2GApXVWBJ3cmxYdF1PP',
    //   name: 'My Collections',
    //   addrs: '0x88D75789480c1aA505c0821E6E89BC4c6D5D87cd',
    // },
    {
      //image:'https://ipfs.infura.io/ipfs/QmXZELvsaPmipdVbKiEM45MuHbRo2H5Kf89fqifyp51Efi',

      image:
        'https://ipfs.infura.io/ipfs/Qmbw9oumJgPeNs7CwygPK8EDSj4Scmc2CK2bPNCceBJJfv',
      //'https://ipfs.infura.io/ipfs/QmXZELvsaPmipdVbKiEM45MuHbRo2H5Kf89fqifyp51Efi',
      name: `Simon's Artwork`,
      addrs: '0x7Eb0811842257A4FcA3F83ca5200Ae85C68387b9',
    },

    {
      image:
        'https://ipfs.infura.io/ipfs/QmVhZAFiddt86MEyjDi26S6fbUEnRttjXYNGYX7sHNCFwt',
      name: `Ben's Artwork`,
      addrs: '0x835c220E3092cAd0262CB5F7Fd297C2De80EC383',
    },
    {
      image:
        'https://ipfs.infura.io/ipfs/QmapYqtZvLwVHNdqkCbod8iEKfDNMtsShA1QU9cGqhKQtH',
      name: `Little Bean's Artwork`,
      addrs: '0xA7945F2Eb8eE1Eef45A1752D9878065c4ED0D630',
    },

    // {
    //   image:
    //     'https://ipfs.moralis.io:2053/ipfs/QmfLbpeVHxReWKNLaXTPcWiafi49eoAL4gRwMGuXtx2Eqe/images/14.png',
    //   name: 'Pixel Show',
    //   addrs: '0xCA34404dD8Bd6537BE76F3A51B379F8949bD7973',
    // },
  ],

  '0x4': [
    {
      image:
        //'https://ipfs.infura.io/ipfs/QmapYqtZvLwVHNdqkCbod8iEKfDNMtsShA1QU9cGqhKQtH',
        'https://lh3.googleusercontent.com/BWCni9INm--eqCK800BbRkL10zGyflxfPwTHt4XphMSWG3XZvPx1JyGdfU9vSor8K046DJg-Q8Y4ioUlWHiCZqgR_L00w4vcbA-w=s0',
      name: `Little Bean's Artwork`,
      addrs: '0x2d07A503Ae116c94A5A054b2e3c6Bd7FEeb307F3',
    },
    {
      image:
        'https://ipfs.infura.io/ipfs/QmVhZAFiddt86MEyjDi26S6fbUEnRttjXYNGYX7sHNCFwt',
      name: `Ben's Artwork`,
      addrs: '0x89891a609306B6c6CfebfeEF90549f9350547464',
    },
    {
      image:
        'https://chc.infura-ipfs.io/ipfs/QmRrzvKgjSCLpH6sErX2kL1KYNebLQokBpTZGYcWd6GqVW',
      name: `Simon's Artwork`,
      addrs: '0xEdD212d7590Fb2A76c2B0238951A3F1E7def7D09',
    },
    {
      image:
        'https://ipfs.infura.io/ipfs/QmenANH7Qs5EsFMECyp1KbWNDXZ2GApXVWBJ3cmxYdF1PP',
      name: `Open Artwork`,
      addrs: '0x86fcf500bf6a0e06cfF9d5302B981C504Da959CD',
    },
  ],
  '0x5': [
    {
      image:
        //'https://ipfs.infura.io/ipfs/QmapYqtZvLwVHNdqkCbod8iEKfDNMtsShA1QU9cGqhKQtH',
        'https://chc.infura-ipfs.io/ipfs/QmapYqtZvLwVHNdqkCbod8iEKfDNMtsShA1QU9cGqhKQtH',
      name: `Little Bean's Artwork`,
      addrs: '0xaaab5fc73b812d51030147aA8C9e28C23d46782c',
    },
    {
      image:
        'https://chc.infura-ipfs.io/ipfs/QmVhZAFiddt86MEyjDi26S6fbUEnRttjXYNGYX7sHNCFwt',
      name: `Ben's Artwork`,
      addrs: '0x046d7F98730Eaf964f46d7286c288655e18AA60f',
    },
    {
      image:
        'https://chc.infura-ipfs.io/ipfs/QmXZELvsaPmipdVbKiEM45MuHbRo2H5Kf89fqifyp51Efi',
      name: `Simon's Artwork`,
      addrs: '0xFc81D0B9862d2006B01E768a3297a4Deb42bD752',
    },
    {
      image:
        'https://chc.infura-ipfs.io/ipfs/QmQjVfatZZGMosfJi4JXaheWeYoFAo5bzVYRJKpMEyQ8ww',
      name: `Open Artwork`,
      addrs: '0x86fcf500bf6a0e06cfF9d5302B981C504Da959CD',
    },
  ],
  /*
  '0x1': [
    {
      image:
        'https://lh3.googleusercontent.com/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB=s130',
      name: 'Bored Ape Yacht Club',
      addrs: '0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D',
    },
    {
      image:
        'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s130',
      name: 'Crypto Punks',
      addrs: '0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB',
    },
    {
      image:
        'https://lh3.googleusercontent.com/l1wZXP2hHFUQ3turU5VQ9PpgVVasyQ79-ChvCgjoU5xKkBA50OGoJqKZeMOR-qLrzqwIfd1HpYmiv23JWm0EZ14owiPYaufqzmj1=s0',
      name: 'Bored Ape Kennel Club',
      addrs: '0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623',
    },
    {
      image:
        'https://lh3.googleusercontent.com/7B0qai02OdHA8P_EOVK672qUliyjQdQDGNrACxs7WnTgZAkJa_wWURnIFKeOh5VTf8cfTqW3wQpozGedaC9mteKphEOtztls02RlWQ=s0',
      name: 'Doodles',
      addrs: '0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e',
    },
    {
      image:
        'https://lh3.googleusercontent.com/7gOej3SUvqALR-qkqL_ApAt97SpUKQOZQe88p8jPjeiDDcqITesbAdsLcWlsIg8oh7SRrTpUPfPlm12lb4xDahgP2h32pQQYCsuOM_s=s0',
      name: '0N1 Force',
      addrs: '0x3bf2922f4520a8BA0c2eFC3D2a1539678DaD5e9D',
    },
    {
      image:
        'https://lh3.googleusercontent.com/lHexKRMpw-aoSyB1WdFBff5yfANLReFxHzt1DOj_sg7mS14yARpuvYcUtsyyx-Nkpk6WTcUPFoG53VnLJezYi8hAs0OxNZwlw6Y-dmI=s0',
      name: 'Mutant Ape Yacht Club',
      addrs: '0x60E4d786628Fea6478F785A6d7e704777c86a7c6',
    },
    {
      image:
        'https://lh3.googleusercontent.com/LIpf9z6Ux8uxn69auBME9FCTXpXqSYFo8ZLO1GaM8T7S3hiKScHaClXe0ZdhTv5br6FE2g5i-J5SobhKFsYfe6CIMCv-UfnrlYFWOM4=s0',
      name: 'CyberKongz',
      addrs: '0x57a204AA1042f6E66DD7730813f4024114d74f37',
    },
    {
      image:
        'https://lh3.googleusercontent.com/LIov33kogXOK4XZd2ESj29sqm_Hww5JSdO7AFn5wjt8xgnJJ0UpNV9yITqxra3s_LMEW1AnnrgOVB_hDpjJRA1uF4skI5Sdi_9rULi8=s0',
      name: 'Cool Cats NFT',
      addrs: '0x1A92f7381B9F03921564a437210bB9396471050C',
    },
  ],*/
}

// export const networkCollections = {
//   '0x13881': [
//     {
//       image:
//         'https://lh3.googleusercontent.com/BWCni9INm--eqCK800BbRkL10zGyflxfPwTHt4XphMSWG3XZvPx1JyGdfU9vSor8K046DJg-Q8Y4ioUlWHiCZqgR_L00w4vcbA-w=s0',
//       name: 'Test Mages',
//       addrs: '0x275d553f426355c20b134D944B5b28D31CDb83DA',
//     },
//     {
//       image:
//         'https://ipfs.moralis.io:2053/ipfs/QmfLbpeVHxReWKNLaXTPcWiafi49eoAL4gRwMGuXtx2Eqe/images/14.png',
//       name: 'Pixel Show',
//       addrs: '0xCA34404dD8Bd6537BE76F3A51B379F8949bD7973',
//     },
//   ],

//   '0x1': [
//     {
//       image:
//         'https://lh3.googleusercontent.com/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB=s130',
//       name: 'Bored Ape Yacht Club',
//       addrs: '0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D',
//     },
//     {
//       image:
//         'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s130',
//       name: 'Crypto Punks',
//       addrs: '0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB',
//     },
//     {
//       image:
//         'https://lh3.googleusercontent.com/l1wZXP2hHFUQ3turU5VQ9PpgVVasyQ79-ChvCgjoU5xKkBA50OGoJqKZeMOR-qLrzqwIfd1HpYmiv23JWm0EZ14owiPYaufqzmj1=s0',
//       name: 'Bored Ape Kennel Club',
//       addrs: '0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623',
//     },
//     {
//       image:
//         'https://lh3.googleusercontent.com/7B0qai02OdHA8P_EOVK672qUliyjQdQDGNrACxs7WnTgZAkJa_wWURnIFKeOh5VTf8cfTqW3wQpozGedaC9mteKphEOtztls02RlWQ=s0',
//       name: 'Doodles',
//       addrs: '0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e',
//     },
//     {
//       image:
//         'https://lh3.googleusercontent.com/7gOej3SUvqALR-qkqL_ApAt97SpUKQOZQe88p8jPjeiDDcqITesbAdsLcWlsIg8oh7SRrTpUPfPlm12lb4xDahgP2h32pQQYCsuOM_s=s0',
//       name: '0N1 Force',
//       addrs: '0x3bf2922f4520a8BA0c2eFC3D2a1539678DaD5e9D',
//     },
//     {
//       image:
//         'https://lh3.googleusercontent.com/lHexKRMpw-aoSyB1WdFBff5yfANLReFxHzt1DOj_sg7mS14yARpuvYcUtsyyx-Nkpk6WTcUPFoG53VnLJezYi8hAs0OxNZwlw6Y-dmI=s0',
//       name: 'Mutant Ape Yacht Club',
//       addrs: '0x60E4d786628Fea6478F785A6d7e704777c86a7c6',
//     },
//     {
//       image:
//         'https://lh3.googleusercontent.com/LIpf9z6Ux8uxn69auBME9FCTXpXqSYFo8ZLO1GaM8T7S3hiKScHaClXe0ZdhTv5br6FE2g5i-J5SobhKFsYfe6CIMCv-UfnrlYFWOM4=s0',
//       name: 'CyberKongz',
//       addrs: '0x57a204AA1042f6E66DD7730813f4024114d74f37',
//     },
//     {
//       image:
//         'https://lh3.googleusercontent.com/LIov33kogXOK4XZd2ESj29sqm_Hww5JSdO7AFn5wjt8xgnJJ0UpNV9yITqxra3s_LMEW1AnnrgOVB_hDpjJRA1uF4skI5Sdi_9rULi8=s0',
//       name: 'Cool Cats NFT',
//       addrs: '0x1A92f7381B9F03921564a437210bB9396471050C',
//     },
//   ],
// }

export const getCollectionsByChain = (chain) => networkCollections[chain]
