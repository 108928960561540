import { ContactsOutlined } from '@ant-design/icons'
import { useMoralisDapp } from 'providers/MoralisDappProvider/MoralisDappProvider'
import { useEffect, useState } from 'react'
import { useMoralisWeb3Api, useMoralisWeb3ApiCall } from 'react-moralis'
import { useIPFS } from './useIPFS'
import {
  SimonCollection,
  BeanCollection,
  BenCollection,
} from 'helpers/collection_images'

import { Alchemy, Network } from 'alchemy-sdk'

/* description
: 
"cool"
image
: 
"https://chc.infura-ipfs.io/ipfs/QmNdwt7vji2sqxyWhyxBiH6AXjmJqQbNgmZHGPqiojPt1M"
name
: 
"Tiger" */

const config = {
  apiKey: 'HIwMSiV9CTTB-ik3qlc8UuXxLupbg5wF',
  network: Network.ETH_GOERLI,
}
const alchemy = new Alchemy(config)

/* {
  image:
    'https://ipfs.infura.io/ipfs/QmTvkbepzqEWkUPvtjWFzQozHLkqk5iXHU4wqm6oJDBBBM',
  name: "Little Bean's Artwork",
  nftname: 'Lucky Cat 招财猫',
  token_id: 10,
} */

//main()

export const useNFTTokenIds = (addr) => {
  const { token } = useMoralisWeb3Api()
  const { chainId } = 'chainId' //useMoralisDapp()
  const { resolveLink } = useIPFS()
  const [NFTTokenIds, setNFTTokenIds] = useState([])
  const [totalNFTs, setTotalNFTs] = useState()
  const [fetchSuccess, setFetchSuccess] = useState(true)
  /*   const {
    fetch: getNFTTokenIds,
    data,
    error,
    isLoading,
  } = useMoralisWeb3ApiCall(token.getAllTokenIds, {
    chain: chainId,
    address: addr,
    limit: 20,
  }) */
  const error = 0
  const isLoading = 0
  const getNFTTokenIds = 0

  const getAllTokens = async (addr) => {
    // Contract address
    if (addr !== 'explore') {
      const address = addr
      // Flag to omit metadata
      const omitMetadata = false

      // Get all NFTs
      const { nfts } = await alchemy.nft.getNftsForContract(address, {
        omitMetadata: omitMetadata,
      })

      let i = 1
      let tempnfts = { total: 0, result: [{}] }
      for (let nft of nfts) {
        //console.log(`${i}. ${nft.rawMetadata.image}`)
        //console.log(nft)
        nft.image = nft.rawMetadata.image
        nft.name = nft.contract.name
        nft.nftname = nft.rawMetadata.name
        nft.token_id = nft.tokenId
        tempnfts.result[i - 1] = nft
        i++
      }
      tempnfts.total = i - 1
      //if (NFTTokenIds == [])
      {
        //console.log(tempnfts)
        setTotalNFTs(tempnfts.total)
        setNFTTokenIds(tempnfts.result)
        //console.log(NFTTokenIds)
        //console.log(NFTTokenIds[0].name)
        //console.log(tempnfts.result[0].name)
      }
      /*       else if (tempnfts.result[0].name != NFTTokenIds[0].name) {
        //console.log(tempnfts)
        setTotalNFTs(tempnfts.total)
        setNFTTokenIds(tempnfts.result)
      } */
      //return tempnfts
    } else {
      //return null
    }
  }
  //const data = getAllTokens(addr)
  useEffect(async () => {
  getAllTokens(addr)
  //console.log('addr:' + addr)
  //console.log(data)
})

  /* useEffect(async () => {
    console.log('--------in Effect---------')
    //console.log(data)
    if (data?.result) {
      const NFTs = data.result
      setTotalNFTs(data.total)
      setFetchSuccess(true)

      if (typeof chainId === 'undefined') {
        if (addr == '0xA7945F2Eb8eE1Eef45A1752D9878065c4ED0D630') {
          setNFTTokenIds(BeanCollection)
          setTotalNFTs(10)
        } else if (addr == '0x7Eb0811842257A4FcA3F83ca5200Ae85C68387b9') {
          setNFTTokenIds(SimonCollection)
          setTotalNFTs(10)
        } else if (addr == '0x835c220E3092cAd0262CB5F7Fd297C2De80EC383') {
          setNFTTokenIds(BenCollection)
          setTotalNFTs(10)
        }
      } else {
        setNFTTokenIds(NFTs)
        //console.log(NFTs)
      }
    }
  }, [data]) */

  return {
    getNFTTokenIds,
    NFTTokenIds,
    totalNFTs,
    fetchSuccess,
    error,
    isLoading,
  }
}
