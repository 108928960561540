/* pages/create-item.js */
import { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { create as ipfsHttpClient } from 'ipfs-http-client'
import { Modal, Alert, Spin, Button } from 'antd'
import { Redirect } from 'react-router-dom'
//import { useRouter } from 'next/router'
import Web3Modal from 'web3modal'
import { nftaddress, nftmarketaddress } from '../config'
import { useMoralisDapp } from 'providers/MoralisDappProvider/MoralisDappProvider'

//import Market from '../artifacts/contracts/Market.sol/NFTMarket.json'

import NFT_Simon from '../artifacts/contracts/NFT_Simon.sol/NFT_Simon.json'
import NFT_Ben from '../artifacts/contracts/NFT_Ben.sol/NFT_Ben.json'
import NFT_LittleBean from '../artifacts/contracts/NFT_LittleBean.sol/NFT_LittleBean.json'
import NFT_Open01 from '../artifacts/contracts/NFT_Open01.sol/NFT_Open01.json'

//const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0')

const ipfsClient = require('ipfs-http-client')

const projectId = '2Ew7omCjnG2Eto5voAO3DyMeGGM'
const projectSecret = '8e3d79189949b6a934c2948ef2868767'
const auth =
  'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64')

const client = ipfsClient.create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  headers: {
    authorization: auth,
  },
})

// client.pin.add('QmeGAVddnBSnKc1DLE7DLV9uuTqo5F7QbaveTjr45JUdQn').then((res) => {
//   console.log(res)
// })

export default function CreateItem() {
  const [fileUrl, setFileUrl] = useState(null)
  const [formInput, updateFormInput] = useState({
    price: '',
    name: '',
    description: '',
  })
  const [redirect, setRedirect] = useState(false)
  const [visible, setVisibility] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible_text, setVisibilityTxt] = useState('hidden')
  const [modalTxt, setModalTxt] = useState('')
  //const router = useRouter()
  const {
    chainId,
    marketAddress,
    contractABI,
    walletAddress,
  } = useMoralisDapp()
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // })
  function resolveAfter2Seconds() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('dd')
      }, 5000)
    })
  }

  async function onChange(e) {
    const file = e.target.files[0]
    try {
      setLoading(true)
      //console.log(loading)
      setVisibility(true)
      setModalTxt(`Uploading Your new NFT to IPFS...`)
      const added = await client.add(file, {
        progress: (prog) => console.log(`received: ${prog}`),
      })
      const url = `https://chc.infura-ipfs.io/ipfs/${added.path}`
      setFileUrl(url)
      //const result = await resolveAfter2Seconds()
      //succList_IPFS()
    } catch (error) {
      failList_IPFS()
      //console.log('Error uploading file: ', error)
    }
    setLoading(false)
    setVisibility(false)
  }

  function succList() {
    let secondsToGo = 10
    const modal = Modal.success({
      title: 'Success!',
      content: `Your NFT was created on the blockchain! Please switch to 'Your NFTs' to check it!`,
    })
    setTimeout(() => {
      modal.destroy()
    }, secondsToGo * 1000)
  }

  function failList() {
    let secondsToGo = 10
    const modal = Modal.error({
      title: 'Error!',
      content: `There was a problem creating your NFT`,
    })
    setTimeout(() => {
      modal.destroy()
    }, secondsToGo * 1000)
  }

  function succList_IPFS() {
    let secondsToGo = 10
    const modal = Modal.success({
      title: 'Success!',
      content: `Your Item was uploaded to IPFS!`,
    })
    setTimeout(() => {
      modal.destroy()
    }, secondsToGo * 1000)
  }

  function failList_IPFS() {
    let secondsToGo = 10
    const modal = Modal.error({
      title: 'Error!',
      content: `There was a problem uploading your Item`,
    })
    setTimeout(() => {
      modal.destroy()
    }, secondsToGo * 1000)
  }

  async function createMarket() {
    const { name, description, price } = formInput
    //if (!name || !description || !fileUrl) return
    if (!name || !fileUrl) return
    //if (!description) description = 'no description'
    /* first, upload to IPFS */
    const data = JSON.stringify({
      name,
      description,
      image: fileUrl,
    })
    //console.log('prepare loading')
    setLoading(true)
    setVisibility(true)
    setModalTxt(
      `Creating Your new NFT. Please confirm the transaction with your wallet! 
      The creation process could take a while. Thank you for your patience!`,
    )
    try {
      const added = await client.add(data)
      const url = `https://ipfs.infura.io/ipfs/${added.path}`
      /* after file is uploaded to IPFS, pass the URL to save it on Polygon */
      //console.log(url)
      //console.log(nftaddress)
      //console.log('go loading')
      createSale(url)
    } catch (error) {
      setLoading(false)
      setVisibility(false)
      failList()
      console.log('Error uploading file: ', error)
    }
  }

  async function createSale(url) {
    const web3Modal = new Web3Modal()
    const connection = await web3Modal.connect()
    const provider = new ethers.providers.Web3Provider(connection)
    const signer = provider.getSigner()
    //console.log('before try')
    try {
      /* next, create the item */
      //let contract = new ethers.Contract(nftaddress, NFT_Simon.abi, signer)
      //let contract = new ethers.Contract(nftaddress, NFT_Ben.abi, signer)
      //let contract = new ethers.Contract(nftaddress, NFT_LittleBean.abi, signer)
      let contract = new ethers.Contract(nftaddress, NFT_Open01.abi, signer)

      //console.log('after signer')
      let transaction = await contract.createToken(url)
      //console.log(url)
      let tx = await transaction.wait()
      //console.log('tx:')
      //console.log(tx)
      let event = tx.events[0]
      //console.log('event:')
      //console.log(event)
      let value = event.args[2]
      let tokenId = value.toNumber()
      //const price = ethers.utils.parseUnits(formInput.price, 'ether')

      /* then list the item for sale on the marketplace */
      /*     contract = new ethers.Contract(nftmarketaddress, Market.abi, signer)
    let listingPrice = await contract.getListingPrice()
    listingPrice = listingPrice.toString()

    transaction = await contract.createMarketItem(nftaddress, tokenId, price, {
      value: listingPrice,
    })
    await transaction.wait() */
      //router.push('/')
      //setRedirect(true)
      setLoading(false)
      setVisibility(false)
      setVisibilityTxt('visible')
      succList()
    } catch (error) {
      setLoading(false)
      setVisibility(false)
      failList()
      console.log('Error creating NFT: ', error)
    }
  }

  /*   return !chainId ? (
    <>
      <Alert
        message="No Wallet installed! You can explore the NFT collections, but not able to create or purchase NFTs."
        type="error"
      />
      <div style={{ marginBottom: '10px' }}></div>
    </>
  ) :  */
  return (
    <div>
      <div>
        <h1 style={{ fontSize: 30, color: '#958006' }}>Create Your NFT:</h1>

        <input
          type="file"
          name="Asset"
          className="my-4"
          onChange={onChange}
          style={{ color: '#958006' }}
        />

        <br></br>
        {fileUrl && <img className="rounded mt-4" width="350" src={fileUrl} />}
        <br></br>
        <input
          placeholder="NFT Name"
          className="mt-8 border rounded p-4"
          style={{ color: '#958006' }}
          onChange={(e) =>
            updateFormInput({ ...formInput, name: e.target.value })
          }
        />

        <br></br>
        <textarea
          placeholder="NFT Description"
          className="mt-2 border rounded p-4"
          onChange={(e) =>
            updateFormInput({ ...formInput, description: e.target.value })
          }
          style={{ height: 180, color: '#958006' }}
        />

        <br></br>
        <input
          placeholder="Asset Price in Eth"
          className="mt-2 border rounded p-4"
          style={{ display: 'none', color: '#958006' }}
          onChange={(e) =>
            updateFormInput({ ...formInput, price: e.target.value })
          }
        />
        <br></br>
        <button
          onClick={createMarket}
          className="font-bold mt-4 bg-pink-500 text-white rounded p-4 shadow-lg"
          style={{ color: '#958006' }}
        >
          Create Digital Asset
        </button>
        <br></br>
        <br></br>
        <p style={{ visibility: visible_text, size: 60, color: '#958006' }}>
          <b>
            Congratulation: NFT created. Please switch to 'Your NFTs' to check
            it!
          </b>
        </p>
      </div>
      <Modal
        title={modalTxt}
        visible={visible}
        /*  onCancel={() => setVisibility(false)}
        onOk={() => list(nftToSend, price)}
        okText="List"*/
        footer={[<Button onClick={() => setVisibility(false)}>Cancel</Button>]}
      >
        <Spin spinning={loading}>
          <img
            src={fileUrl}
            style={{
              width: '250px',
              margin: 'auto',
              borderRadius: '10px',
              marginBottom: '15px',
            }}
          />
        </Spin>
      </Modal>
    </div>
  )
}
