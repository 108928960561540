//till now all following contracts are deployed with the private key of account 'Account 1'

//ropsten test net via alchemyapi
// export const nftmarketaddress = '0x88D75789480c1aA505c0821E6E89BC4c6D5D87cd'
// export const nftaddress = '0xF372100f1c662867708e78F3dD264D4cD15F6BcD'

//local hardhat net via npx hardhat node
//export const nftmarketaddress = '0x5FbDB2315678afecb367f032d93F642f64180aa3'
//export const nftaddress = '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512'

//polygon mumbai test net via maticvigil api ----- not working
//export const nftmarketaddress = '0x47c5e40890bcE4a473A49D7501808b9633F29782'
//export const nftaddress = '0x29b2440db4A256B0c1E6d3B4CDcaA68E2440A08f'

//polygon mumbai test net via alchemyapi
//export const nftmarketaddress = '0x722A42ab98b153e3279e2c0e1FE1cbcbc7E8c521'
//export const nftaddress = '0xBec77193c2235C5b95a9961807F3D4EC96CD713D'

//polygon mumbai test net via alchemyapi: nft contract approval with an extra market contract for moralis project
//export const nftmarketaddress = '0xC7DC2bF3A999FA7252757C83738aFC26EeF8F090'
//polygon mumbai test nft contract first one for general start testing
//export const nftaddress = '0x88D75789480c1aA505c0821E6E89BC4c6D5D87cd'

//polygon mumbai test nft contract : Simon
//export const nftaddress = '0x7Eb0811842257A4FcA3F83ca5200Ae85C68387b9'

//polygon mumbai test nft contract : Ben
//export const nftaddress = '0x835c220E3092cAd0262CB5F7Fd297C2De80EC383'

//polygon mumbai test nft contract : LittleBean
//export const nftaddress = '0xA7945F2Eb8eE1Eef45A1752D9878065c4ED0D630'

//Rinkeby ------Start
//export const nftmarketaddress = '0xcD41E288802f7DD4d48aE003BD39deAC2d4E7057'

//little bean
//export const nftaddress = '0x2d07A503Ae116c94A5A054b2e3c6Bd7FEeb307F3'

//open01
//export const nftaddress = '0x43a84b419482da5c7e962af54cc3e1652ad2a2b2'

//Ben
//export const nftaddress = '0x89891a609306B6c6CfebfeEF90549f9350547464'

//Simon
//export const nftaddress = '0xEdD212d7590Fb2A76c2B0238951A3F1E7def7D09'

//Rinkeby -------End

//Goerli ------Start
export const nftmarketaddress = '0x17c59Aae5c40CE9e891D47F4cDD9585CE8780967'

//Ben
//export const nftaddress = '0x046d7F98730Eaf964f46d7286c288655e18AA60f'

//Simon

//export const nftaddress = '0xFc81D0B9862d2006B01E768a3297a4Deb42bD752'
//nft_Simon deployed to: 0xcD41E288802f7DD4d48aE003BD39deAC2d4E7057 - bad
//nft_Ben deployed to: 0x2d07A503Ae116c94A5A054b2e3c6Bd7FEeb307F3 -bad

//little Bean
//export const nftaddress = '0xaaab5fc73b812d51030147aA8C9e28C23d46782c'

//open
export const nftaddress = '0x86fcf500bf6a0e06cfF9d5302B981C504Da959CD'

//Goerli -------End
//npm run build
//scp -r ./build/* root@91.216.248.90:/var/www/veverchain.com/html
//ssh root@91.216.248.90
//systemctl restart nginx
